import DetailSection from '@/common/components/DetailSection';
import ListEmptyText from '@/common/components/ListEmptyText';
import { useCreateWorkOrder } from '@/common/hooks/useCreateWorkOrder';
import WorkOrderCard from '@/components/workOrders/WorkOrderCard';
import { useConfirmModal } from '@/context/ConfirmModalContext';
import { useTemplateContext } from '@/context/TemplateContext';
import { WorkOrderFormDataType, useAddWorkOrder } from '@/modules/workOrders/hooks/useAddWorkOrder';
import { toastPromiseOptions, useToast } from '@/utils/atoms/toast';
import { TASK } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';
import { gql } from '@apollo/client';
import { WorkOrderTemplateSelectButton } from '../WorkOrderTemplateSelectButton';
import { useRemoveWorkOrderLinkMutation, useWorkOrderLinksSuspenseQuery } from './index.generated';

type Props = {
  workOrderId: number;
  // 本来ならこのコンポーネント内で、navigateを使ってページ遷移を行うべきですが、hashのみの変更だと画面が変更されないので、親コンポーネントで遷移を行っています
  onWorkOrderClicked: (id: number) => void;
};

gql`
  query WorkOrderLinks($id: Float!) {
    workOrder(id: $id) {
      id
      inboundLinks {
        id
        inbound {
          ...WorkOrderCard_WorkOrder
        }
      }
      outboundLinks {
        id
        outbound {
          ...WorkOrderCard_WorkOrder
        }
      }
    }
  }
  mutation RemoveWorkOrderLink($id: Int!) {
    removeWorkOrderLink(id: $id)
  }
`;

const WorkOrderLinks = ({ workOrderId, onWorkOrderClicked }: Props) => {
  const { confirm } = useConfirmModal();
  const { toastPromise } = useToast();
  const { t, t_ns } = useTranslation(TASK);
  const {
    data: {
      workOrder: { inboundLinks, outboundLinks },
    },
    refetch,
  } = useWorkOrderLinksSuspenseQuery({
    variables: { id: workOrderId },
  });
  const links = [
    ...inboundLinks.map((data) => ({
      id: data.id,
      workOrder: data.inbound,
    })),
    ...outboundLinks.map((data) => ({
      id: data.id,
      workOrder: data.outbound,
    })),
  ];
  const { workOrderTemplates, fetchAndSetDefaultWorkOrderTemplate } = useTemplateContext();
  const [removeWorkOrderLinkMutation] = useRemoveWorkOrderLinkMutation();
  const { createWorkOrderInput, CreateWorkOrderModal } = useCreateWorkOrder();
  const { addWorkOrder } = useAddWorkOrder();

  const createWorkOrder = async (payload: WorkOrderFormDataType): Promise<boolean> => {
    await addWorkOrder({ ...payload, inboundLink: { inboundId: workOrderId } });
    refetch();
    return true;
  };

  const onTemplateClick = async (templateId: number) => {
    await fetchAndSetDefaultWorkOrderTemplate(templateId);
    await createWorkOrderInput({
      submit: createWorkOrder,
      displayStatus: true,
    });
  };

  const removeWorkOrderLink = async (linkId: number) => {
    const result = await confirm(
      t('confirmation.task.remove-task-link'),
      t('warning.remove-task-link'),
      t('actions.remove-task-link')
    );

    if (!result) return;

    await toastPromise(
      removeWorkOrderLinkMutation({ variables: { id: linkId } }),
      toastPromiseOptions(t, 'update', 'task'),
      true
    );
    refetch();
  };

  return (
    <DetailSection
      title={t_ns('link-task')}
      menu={
        <WorkOrderTemplateSelectButton
          workOrderTemplates={workOrderTemplates}
          onTemplateClick={onTemplateClick}
        />
      }
    >
      {links.length === 0 ? (
        <ListEmptyText text={t_ns('no-liked-tasks')} textAlign='left' />
      ) : (
        <>
          {links.map(({ id, workOrder }) => (
            <WorkOrderCard
              key={id}
              onWorkOrderClicked={() => onWorkOrderClicked(workOrder.id)}
              workOrder={workOrder}
              workOrderActions={{
                removeWorkOrderLink: () => removeWorkOrderLink(id),
              }}
            />
          ))}
        </>
      )}
      {CreateWorkOrderModal}
    </DetailSection>
  );
};

export default WorkOrderLinks;
